import React from "react"
import { Problem } from "../Problem/problem"
import * as S from './styles'
import computer from '../../assets/imgs/advantages-image.svg'
import icon9 from '../../assets/imgs/icon9.svg'
import icon10 from '../../assets/imgs/icon10.svg'
import icon11 from '../../assets/imgs/icon11.svg'



export const AdvantagesSection = (props) => {
    return (
        <S.AdvantagesContainer id="advantages">
            <S.Subtitle>Vantagens de contratar o LogHub</S.Subtitle>
            <S.DividerLine />
            <S.Title>Para resolver os problemas da sua frota</S.Title>
            <S.AdvantagesContent>
                <S.ComputerImage src={computer} alt="sistema-loghub" />
                <div>
                    <S.AuxiliarTextOne>Você sofre com algum dos problemas abaixo?</S.AuxiliarTextOne>
                    <Problem
                        icon={icon9}
                        title={'Falta de visibilidade e controle da sua frota?'}
                        text={'Com o rastreamento e o mapa do LogHub, você tem uma visão completa e precisa da sua frota.'}
                    />
                    <Problem
                        icon={icon10}
                        title={'Gestão ineficaz da manutenção de veículos?'}
                        text={'No LogHub você poder agendar as manutenções dos seu veículos e acompanhar todo o histórico de manutenções.'}
                    />
                    <Problem
                        icon={icon11}
                        title={'Baixa produtividade dos motoristas?'}
                        text={'Com o LogHub você consegue detectar comportamentos de motoristas fora do padrão e agir baseado em dados.'}
                    />
                    <S.AuxiliarTextTwo>O LogHub é para você!</S.AuxiliarTextTwo>
                    <S.NavBtn>
                        {/* <S.NavBtnLink to="contact" smooth={true} offset={-80} >Contratar</S.NavBtnLink> */}
                        <S.NavBtnLink href='https://web.whatsapp.com/send?phone=5531984063020' target="_blank">Contratar</S.NavBtnLink>
                    </S.NavBtn>
                </div>
            </S.AdvantagesContent>
        </S.AdvantagesContainer>
    )
}