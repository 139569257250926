import React from "react"
import * as S from './styles'
import heroMainImage from '../../assets/imgs/hero-image.svg'
import logos from '../../assets/imgs/clients-logos.svg'



export const HeroSection = () => {
    return (
        <S.HeroContainer id="home">
            <S.TextContainer>
                <S.Subtitle>Tenha uma visão 360º da sua frota.</S.Subtitle>
                <S.Title>Gestão de frotas descomplicada</S.Title>
                <S.NavBtn>
                    {/* <S.NavBtnLink to="contact" smooth={true} spy={true} activeClass="active" >Contratar</S.NavBtnLink> */}
                    <S.NavBtnLink href='https://web.whatsapp.com/send?phone=5531984063020' target="_blank">Contratar</S.NavBtnLink>
                </S.NavBtn>
                <S.ClientsContainer>
                    <S.Subtitle>Algumas marcas que confiam em nós</S.Subtitle>
                    <S.DividerLine />
                    <S.ClientsLogo src={logos} alt="logos clientes" />
                </S.ClientsContainer>
            </S.TextContainer>
            <S.ImageContainer>
                <S.HeroImage src={heroMainImage} alt="imagem principal" />
            </S.ImageContainer>
        </S.HeroContainer>
    )
}