import styled from "styled-components";

export const WhatsappIcon = styled.a`
    position: fixed;
    bottom: 60px;
    right: 60px;
    cursor: pointer;
    z-index: 1000;

    img {
        width: 80px;
    }
`