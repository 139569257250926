import React from "react"
import * as S from './styles'
import logo from '../../assets/imgs/logHub-logo-footer.svg'
import icon15 from '../../assets/imgs/icon15.svg'


export const Footer = () => {
    return (
        <div id='footer'>
            <S.FooterLine />
            <S.FooterContainer>
                <S.LogoContainer>
                    <S.Logo to="home" smooth={true} offset={-80} spy={true} activeClass="active">
                        <S.LogoImage src={logo} alt='Logo' />
                    </S.Logo>
                    <S.ButtonsContainer>
                        <S.LinksButtons href="">Quem somos</S.LinksButtons>
                        <S.LinksButtons href="">Trabalhe conosco</S.LinksButtons>
                        <S.LinksButtons href="">Blog</S.LinksButtons>
                    </S.ButtonsContainer>
                    <a href="https://www.instagram.com/loghubfrotas/"><S.InstagramIcon src={icon15} alt='Icone Instagram' /></a>
                </S.LogoContainer>
                <S.DividerLine />
                <div>
                    <S.Stamp>© 2022 Equipe BHX.</S.Stamp>
                    <S.DocumentsContainer>
                        <a href="">Termos de uso</a>
                        <a href="">Política de privacidade</a>
                    </S.DocumentsContainer>
                </div>
            </S.FooterContainer>
        </div>

    )
}