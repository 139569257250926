import React from "react"
import { Skill } from "../Skill/skill"
import * as S from './styles'
import icon1 from '../../assets/imgs/icon1.svg'
import icon2 from '../../assets/imgs/icon2.svg'
import icon3 from '../../assets/imgs/icon3.svg'
import icon4 from '../../assets/imgs/icon4.svg'
import icon5 from '../../assets/imgs/icon5.svg'
import icon6 from '../../assets/imgs/icon6.svg'
import icon7 from '../../assets/imgs/icon7.svg'
import icon8 from '../../assets/imgs/icon8.svg'
import bgImage1 from '../../assets/imgs/bg-image1.svg'
import bgImage2 from '../../assets/imgs/bg-image2.svg'




export const AboutSection = () => {
    return (
        <S.AboutContainer id="about">
            <S.Subtitle>O que é o LogHub</S.Subtitle>
            <S.DividerLine />
            <S.Title>A melhor solução para a gestão da sua frota</S.Title>
            <S.BackgroundImageOne src={bgImage1} />
            <S.BackgroundImageTwo src={bgImage2} />
            <S.SkillsContainer>
                <S.LineOne>
                    <Skill
                        icon={icon1}
                        title={'Mapa de veículos'}
                        text={'Tenha todos os veículos da sua frota na visão de mapa e selecione um carro para ver os detalhes.'}
                    />
                    <Skill
                        icon={icon2}
                        title={'Rastreamento em tempo real'}
                        text={'Saiba onde cada veículo está a qualquer momento, se está em movimento, qual a velocidade, etc.'}
                    />
                    <Skill
                        icon={icon3}
                        title={'Gestão de manutenções'}
                        text={'Mantenha a manutenção dos veiculos em dia com o agendamento do LogHub.'}
                    />
                </S.LineOne>
                <S.LineTwo>
                    <Skill
                        icon={icon4}
                        title={'Envio de mensagens'}
                        text={'Mantenha o contato. Troque mensagens com os motoristas durante a operação.'}
                    />
                    <Skill
                        icon={icon5}
                        title={'Relatórios de gestão'}
                        text={'Acompanhe relatórios de faturamento, reembolso, horas trabalhadas, entre outros.'}
                    />
                    <Skill
                        icon={icon6}
                        title={'Scanner e Diagnóstico'}
                        text={'Tenha um disgnóstico dos veículos da sua frota de maneira rápida e precisa, em poucos cliques.'}
                    />
                </S.LineTwo>
                <S.LineThree>
                    <Skill
                        icon={icon7}
                        title={'Agendamento de viagens'}
                        text={'Agende viagens com origem, destino, além de múltiplas paradas no meio do percurso.'}
                    />
                    <Skill
                        icon={icon8}
                        title={'BDT'}
                        text={'Crie e pesquise boletins diários de tráfego sempre que precisar.'}
                    />
                </S.LineThree>
            </S.SkillsContainer>

        </S.AboutContainer>
    )
}