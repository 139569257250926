import React, { useState } from "react";
import * as S from "./styles";
import { useForm } from "../../hooks/useForm";
import icon12 from "../../assets/imgs/icon12.svg";
import icon13 from "../../assets/imgs/icon13.svg";
import icon14 from "../../assets/imgs/icon14.svg";
import bgImage from "../../assets/imgs/bg-image3.svg";
import axios from "axios";

export const ContactSection = () => {
  const [form, onChange, clear] = useForm({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    frota: "",
    message: "",
  });
  const [formSended, setFormSended] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const handleSubmitForm = (event) => {
    setIsLoading(true);
    event.preventDefault();
    axios.post("https://api.loghub.com.br/api/v2/contacts", form).then(() => {
      setIsLoading(false);
      clear();
      setFormSended(true);
    });
  };

  return (
    <S.ContactContainer id="contact">
      <S.Subtitle>Ainda precisa de ajuda para decidir?</S.Subtitle>
      <S.DividerLine />
      <S.Title>Entre em contato conosco</S.Title>
      <S.BackgroundImage src={bgImage} />
      <S.ContentContainer>
        <S.NonFormContainer>
          <S.Text>
            Estamos à disposição para esclarecer qualquer ponto que lhe dê
            segurança para contratar o LogHub e levar a sua frota para o próximo
            nível.
          </S.Text>
          <S.OtherContacts>
            <S.ContactTitle>Outras formas de contato</S.ContactTitle>
            <S.DividerContactLine />
            <S.SingleContactContainer>
              <img src={icon13} alt="icone-whatsapp" />
              <p>(31) 98406-3020</p>
              <img src={icon14} alt="icone-telefone" />
              <a href="mailto:contato@bhxs.com.br" target="_blank">
                contato@bhxs.com.br
              </a>
            </S.SingleContactContainer>
          </S.OtherContacts>
        </S.NonFormContainer>

        {formSended ? (
          <S.SuccessMessageContainer>
            <S.SuccessMessage>
              Sua mensagem foi recebida! Em breve um de nossos consultores
              entrará em contato.
            </S.SuccessMessage>
          </S.SuccessMessageContainer>
        ) : (
          <S.FormContainer isloading={isloading} onSubmit={handleSubmitForm}>
            <S.CircularProgressContainer isloading={isloading}>
              <S.StyledCircularProgress isloading={isloading} />
            </S.CircularProgressContainer>
            <S.InputContainer>
              <S.FormLabel htmlFor="name">Nome</S.FormLabel>
              <S.StyledTextField
                name={"name"}
                value={form.name}
                onChange={onChange}
                placeholder={"João"}
                variant={"outlined"}
                margin={"normal"}
                required
                type={"text"}
                fullWidth
              />
            </S.InputContainer>

            <S.InputContainer>
              <S.FormLabel htmlFor="lastname">Sobrenome</S.FormLabel>
              <S.StyledTextField
                name={"lastname"}
                value={form.lastname}
                onChange={onChange}
                placeholder={"da Silva"}
                variant={"outlined"}
                margin={"normal"}
                required
                type={"text"}
                fullWidth
              />
            </S.InputContainer>

            <S.InputContainer>
              <S.FormLabel htmlFor="email">Email</S.FormLabel>
              <S.StyledTextField
                name={"email"}
                value={form.email}
                onChange={onChange}
                placeholder={"joaodasilva@email.com"}
                variant={"outlined"}
                margin={"normal"}
                required
                type={"email"}
              />
            </S.InputContainer>

            <S.InputContainer>
              <S.FormLabel htmlFor="phone">Telefone</S.FormLabel>
              <S.StyledTextField
                name={"phone"}
                value={form.phone}
                onChange={onChange}
                placeholder={"(00) 00000-0000"}
                variant={"outlined"}
                margin={"normal"}
                required
                type={"tel"}
              />
            </S.InputContainer>

            <S.FrotaInputContainer>
              <S.FormLabel htmlFor="phone">Tamanho da frota</S.FormLabel>
              <S.StyledTextField
                name={"frota"}
                value={form.frota}
                onChange={onChange}
                placeholder={"Ex: 5 carros, 2 camihões..."}
                variant={"outlined"}
                margin={"normal"}
                required
                type={"text"}
              />
            </S.FrotaInputContainer>

            <S.MessageInputContainer>
              <S.FormLabel htmlFor="message">Mensagem</S.FormLabel>
              <S.StyledTextField
                name={"message"}
                value={form.message}
                onChange={onChange}
                placeholder={"Mensagem"}
                variant={"outlined"}
                margin={"normal"}
                required
                type={"text"}
                multiline={true}
                rows={4}
              />
            </S.MessageInputContainer>

            <S.StyledButton type="submit">Enviar</S.StyledButton>
          </S.FormContainer>
        )}
      </S.ContentContainer>
    </S.ContactContainer>
  );
};
