import React from "react"
import * as S from './styles'
import * as M from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { questions } from "../../assets/constants/constants";





export const QuestionsSection = () => {
    return (
        <S.QuestionsContainer id="about">
            <S.Title>Perguntas frequentes</S.Title>
            <S.Subtitle>Algumas questões são bem comuns para quem precisa contratar um sistema de gestão de frotas. <br></br>Veja se a sua dúvida já foi respondida abaixo.</S.Subtitle>

            <S.AccordionContainer>
                {questions.map((item, index) => {
                    return (
                        <M.Accordion key={index} >
                            <M.AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <S.Question>{item.question}</S.Question>
                            </M.AccordionSummary>
                            <M.AccordionDetails>
                                <S.Answer>
                                    {item.answer}
                                </S.Answer>
                            </M.AccordionDetails>
                        </M.Accordion>
                    )
                })}

                
                <M.Accordion>
                    <M.AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <S.Question>Quais são as informações que o Rastreamento Loghub me fornece?</S.Question>
                    </M.AccordionSummary>
                    <M.AccordionDetails>
                        <ul>
                            <li><S.Answer>Consumo de combustível</S.Answer></li>
                            <li><S.Answer>Localização em tempo real</S.Answer></li>
                            <li><S.Answer>Como a equipe está dirigindo</S.Answer></li>
                            <li><S.Answer>Rotas mais eficientes</S.Answer></li>
                            <li><S.Answer>Dados sobre velocidade</S.Answer></li>
                            <li><S.Answer>Alertas de manutenção do carro</S.Answer></li>
                        </ul>
                        
                    </M.AccordionDetails>
                </M.Accordion>
            </S.AccordionContainer>
        </S.QuestionsContainer>
    )
}

