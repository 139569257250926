import styled from "styled-components";
import { Link as LinkS } from 'react-scroll'
import { mediaScreenWidth } from "../../assets/constants/constants";
import { mediumMediaScreenWidth } from "../../assets/constants/constants";

export const HeroContainer = styled.div`
    max-width: 100%;
    height: auto;
    z-index: 1;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 5vw;
    align-items: center;
    padding: 63px 10vw;


    @media screen and (max-width: 735px /*${mediumMediaScreenWidth}*/) {
        transition: 0.2s all ease;
        grid-template-columns: 100%;
        grid-template-rows: 240px 330px;
        column-gap: 0px;
        row-gap: 50px;
        padding: 24px 0vw;
        margin: 0;
    }


    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0.4s all ease;
        grid-template-columns: 360px;
        grid-template-rows: 240px 330px;
        column-gap: 0px;
        row-gap: 50px;
        padding: 24px 0vw;
        margin: 0;
    }

    
`

export const Title = styled.h1`
    width: 518px;
    height: 128px;
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: #13171E;
    margin: 10px 0px;


    @media screen and (max-width: 735px) {
        transition: 0.4s all ease;
        font-size: 44px;
        line-height: 52px;
        margin: 2px 0px;
        width: 400px;
    }

    @media screen and (max-width: 450px) {
        transition: 0.4s all ease;
        font-size: 40px;
        line-height: 48px;
        margin: 2px 0px;
        width: 313px;
    }

    
`

export const Subtitle = styled.h6`
    font-family: 'Exo 2';
    color: #787F89;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0px;
`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
`

export const NavBtnLink = styled.a` /* passar para LinkS para ir para o formulario*/
    padding: 12px 32px 12px 32px;
    margin: 10px 0px;
    border-radius: 8px;
    background: #0066FF;
    white-space: nowrap;
    padding: 12px 32px;
    color: #F1F4FA;
    font-size: 16px;
    font-family: Roboto;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #2E81FF;
        color: #F1F4FA;
    }

    &:active {
        transition: all 0.2s ease-in-out;
        background: #0054D2;
        color: #F1F4FA;
    }

    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0.4s all ease;
        margin: 0px;
    }
`


export const ClientsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    align-items: flex-start;
    margin-top: 60px;
`


export const DividerLine = styled.hr`
    width: 136px;
    height: 1px;
    border: none;
    background: linear-gradient(to left, #0066FF 0%, #C055F4 100%);
    justify-self: start;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 11px;
`


export const ClientsLogo = styled.img`
    width: 515px;

    @media screen and (max-width: 570px) {
        transition: 0.4s all ease;
        width: 400px;
    }

    @media screen and (max-width: 460px) {
        transition: 0.4s all ease;
        width: 311px;
    }
`

export const HeroImage = styled.img`
    align-self: center;
    width: 33vw;
    min-width: 311px;

    @media screen and (max-width: ${mediaScreenWidth}) {
        height: 240px;
        width: 311px;
    }
`

export const ImageContainer = styled.div`
    max-width: 512px;
    min-width: 311px;
    /* background: red; */

    @media screen and (max-width: 735px) {
        height: auto;
        width: 100%;
        grid-row-start: 1;
        grid-row-end: 2;
        margin: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center;
    }
`

export const TextContainer = styled.div`
    width: 33vw;
    min-width: 311px;


    @media screen and (max-width: 735px/*${mediumMediaScreenWidth}*/) {
        grid-row-start: 2;
        grid-row-end: 3;
        margin-left: 50px;
    }


    @media screen and (max-width: ${mediaScreenWidth}) {
        grid-row-start: 2;
        grid-row-end: 3;
        margin-left: 24px;
    }
`