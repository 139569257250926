import styled from "styled-components";
import { CircularProgress, TextField } from "@mui/material";
import { mediaScreenWidth } from "../../assets/constants/constants";
import { mediumMediaScreenWidth } from "../../assets/constants/constants";


export const ContactContainer = styled.div`
    z-index: 1;
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 90px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        margin-top: 40px;
    }
`

export const Subtitle = styled.h6`
    font-family: 'Exo 2';
    color: #787F89;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 15px 0px;
`

export const DividerLine = styled.hr`
    width: 96px;
    height: 1px;
    border: none;
    background: linear-gradient(to left, #0066FF 0%, #C055F4 100%);
    margin: 0px;
`

export const Title = styled.h2`
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #363C44;
    margin-bottom: 70px;
    margin-top: 10px;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        font-weight: 600;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 24px;
    }

    @media screen and (max-width: 530px) {
        transition: 0.2s all ease;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 24px;
    }
`

export const Text = styled.p`
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #787F89;


    @media screen and (max-width: 450px) {
        transition: 0.4s all ease;
        width: 312px;
    }
`

export const OtherContacts = styled.div`

`

export const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 354px 35vw;
    grid-template-rows: 1fr;
    column-gap: 7vw;


    @media screen and (max-width: 770px ) {
        transition: 0.2s all ease;
        grid-template-columns: 500px;
        grid-template-rows: 850px;
        column-gap: 0px;
    }

    @media screen and (max-width: 630px) {
        transition: 0.2s all ease;
        grid-template-columns: 312px;
        grid-template-rows: 1150px;
        column-gap: 0px;
    }
`

export const FormContainer = styled.form`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 2fr 1fr;
    column-gap: 24px;
    row-gap: 18px;
    opacity: ${props => props.isloading ? '0.5' : '1'};
    width: 35vw;
    min-width: 311px;

    @media screen and (max-width: 770px ) {
        transition: 0.4s all ease;
        position: absolute;
        top: 290px;
        left: 30vw;
    }


    @media screen and (max-width: 630px) {
        transition: 0.4s all ease;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 270px;
        left: 120px;
    }

    @media screen and (max-width: 480px) {
        transition: 0.4s all ease;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 270px;
        left: 30px;
    }
`

export const NonFormContainer = styled.div`

    @media screen and (max-width: 770px ) {
        transition: 0.4s all ease;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 890px;
    }


    @media screen and (max-width: 630px) {
        transition: 0.2s all ease;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 1150px;
    }
`

export const CircularProgressContainer = styled.div`
    display: ${props => props.isloading ? 'flex' : 'none'};
    z-index: 2;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 35vw;
    min-width: 311px;
    min-height: 500px;
    align-items: center;
    justify-content: center;
`

export const StyledCircularProgress = styled(CircularProgress)`
    display: ${props => props.isloading ? 'block' : 'none'};
    z-index: 3;
`

export const SuccessMessage = styled.h2`
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #363C44;
    margin-bottom: 70px;
    margin-top: 10px;
    text-align: center;
`

export const SuccessMessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30vw;

    @media screen and (max-width: 770px) {
        transition: 0.2s all ease;
        position: absolute;
        top: 450px;
        left: 250px;
    }

    @media screen and (max-width: 630px) {
        transition: 0.2s all ease;
        position: absolute;
        top: 450px;
        left: 170px;
    }
`

export const ContactTitle = styled.h5`
    font-family: 'Exo 2';
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #49505A;
    margin-top: 116px;
    margin-bottom: 16px;

    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0.2s all ease;
        margin-top: 10px;
    }
`

export const DividerContactLine = styled.hr`
    width: 114px;
    height: 2px;
    border: none;
    background: linear-gradient(to left, #0066FF 0%, #C055F4 100%);
    margin: 0px;
`

export const SingleContactContainer = styled.div`
    margin-top: 20px;
    display: grid;
    align-items: center;
    grid-template-columns: 20px 217px;
    grid-template-rows: 18px 18px 18px;
    column-gap: 15px;
    row-gap: 27px;

    p {
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #787F89;
    }

    a {
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #787F89;
    }

`

export const MessageInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
    
`

export const FrotaInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
    
`

export const StyledButton = styled.button`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    padding: 12px 32px 12px 32px;
    width: 110px;
    height: 50px;
    margin: 0px 0px;
    border-radius: 8px;
    background: #FFFFFF;
    white-space: nowrap;
    padding: 12px 32px;
    color: #0066FF;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    outline: none;
    border: 1px solid #0066FF;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: rgba(46, 129, 255, 0.25);
        color: #0066FF;
    }

    &:active {
        transition: all 0.2s ease-in-out;
        background: rgba(0, 84, 210, 0.25);
        color: #0066FF;
    }

    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0.2s all ease;
        width: 311px;
    }
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const FormLabel = styled.label`
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #787F89;
`

export const StyledTextField = styled(TextField)`
    margin-top: 5px !important;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
    }
`

export const BackgroundImage = styled.img`
    position: absolute;
    top: 280px;
    left: -20px;
    width: 260px;
    height: 242px;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        display: none;
    }
`