import styled from "styled-components";
import { mediaScreenWidth } from "../../assets/constants/constants";
import { mediumMediaScreenWidth } from "../../assets/constants/constants";

export const AboutContainer = styled.div`
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;

    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0.2s all ease;
        margin-bottom: 0px;
    }
`

export const Subtitle = styled.h6`
    font-family: 'Exo 2';
    color: #787F89;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0px;
`

export const DividerLine = styled.hr`
    width: 96px;
    height: 1px;
    border: none;
    background: linear-gradient(to left, #0066FF 0%, #C055F4 100%);
    margin: 0px;
`

export const Title = styled.h2`
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #363C44;
    margin: 4px 0px;


    @media screen and (max-width: 830px) {
        transition: 0.2s all ease;
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        width: 600px;
        margin: 8px 0px;
    }

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        width: 312px;
        margin: 8px 0px;
    }

    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0.2s all ease;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        width: 312px;
        margin: 8px 0px;
    }
`

export const SkillsContainer = styled.div`
    margin-left: 20px;
    margin-top: 70px;
    display: grid;
    column-gap: 24px;
    row-gap: 60px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.4s all ease;
        display: flex;
        flex-direction: column;
        margin-top: 38px;
        row-gap: 0px;
    }

`

export const LineOne = styled.div`
    display: flex;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        display: flex;
        flex-direction: column;
    }

`

export const LineTwo = styled.div`
    display: flex;
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        display: flex;
        flex-direction: column;
    }


`

export const LineThree = styled.div`
    display: flex;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        display: flex;
        flex-direction: column;
    }

`

export const BackgroundImageOne = styled.img`
    position: absolute;
    top: 150px;
    right: 17px;
    width: 315px;
    height: 293px;

    @media screen and (max-width: 1000px) {
        transition: 0.4s all ease;
        display: none;
    }
`

export const BackgroundImageTwo = styled.img`
    position: absolute;
    top: 450px;
    left: 0px;

    @media screen and (max-width: 1000px) {
        transition: 0.4s all ease;
        display: none;
    }
`