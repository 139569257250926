import React, { useState } from "react";
import { Sidebar } from "../../components/Sidebar/sidebar";
import { Navbar } from "../../components/Navbar/navbar";
import { Footer } from "../../components/Footer/footer";
import { AboutSection } from "../../components/AboutSection/aboutSection";
import { AdvantagesSection } from "../../components/AdvantagesSection/advantagesSection";
import { PackagesSection } from "../../components/PackagesSection/packagesSection";
import { ContactSection } from "../../components/ContactSection/contactSection";
import { QuestionsSection } from "../../components/QuestionsSection/questionsSection";
import { HeroSection } from "../../components/HeroSection/heroSection";
import whastapp from "../../assets/imgs/whatsapp.png";
import * as S from "./styles";
import $ from "jquery";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHere, setIsHere] = useState("");

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSetActive = (to) => {
    setIsHere(to);
  };

  $(window).scroll(function () {
    if (
      $(window).width() >= 360 &&
      $(window).width() <= 560 &&
      $(document).scrollTop() > 5420
    ) {
      $("#wpp").css({
        position: "fixed",
        bottom: "470px",
        right: "30px",
        transition: "0.5s all ease",
      });
    } else if (
      $(window).width() > 560 &&
      $(window).width() <= 630 &&
      $(document).scrollTop() > 5400
    ) {
      $("#wpp").css({
        position: "fixed",
        bottom: "290px",
        right: "30px",
        transition: "0.5s all ease",
      });
    } else if (
      $(window).width() >= 631 &&
      $(window).width() <= 735 &&
      $(document).scrollTop() > 4570
    ) {
      $("#wpp").css({
        position: "fixed",
        bottom: "290px",
        right: "30px",
        transition: "0.5s all ease",
      });
    } else if (
      $(window).width() >= 736 &&
      $(window).width() <= 760 &&
      $(document).scrollTop() > 4050
    ) {
      $("#wpp").css({
        position: "fixed",
        bottom: "290px",
        right: "30px",
        transition: "0.5s all ease",
      });
    } else if (
      $(window).width() >= 761 &&
      $(window).width() <= 875 &&
      $(document).scrollTop() > 3785
    ) {
      $("#wpp").css({
        position: "fixed",
        bottom: "290px",
        right: "30px",
        transition: "0.5s all ease",
      });
    } else if (
      $(window).width() >= 876 &&
      $(window).width() <= 1030 &&
      $(document).scrollTop() > 3570
    ) {
      $("#wpp").css({
        position: "fixed",
        bottom: "240px",
        right: "30px",
        transition: "0.5s all ease",
      });
    } else if (
      $(window).width() >= 1031 &&
      $(window).width() <= 1631 &&
      $(document).scrollTop() > 3330
    ) {
      $("#wpp").css({
        position: "fixed",
        bottom: "240px",
        right: "30px",
        transition: "0.5s all ease",
      });
    } else if ($(window).width() >= 1632 && $(document).scrollTop() > 3060) {
      $("#wpp").css({
        position: "fixed",
        bottom: "240px",
        right: "30px",
        transition: "0.5s all ease",
      });
    } else {
      $("#wpp").css({
        position: "fixed",
        bottom: "30px",
        right: "30px",
      });
    }
  });

  // $(window).scrollTop(function () {
  //     $("#wpp").css("top", Math.max(0, 250 - $(this).scrollTop));
  // });

  // console.log(Math.max(0, 250 - $(this).scrollTop))

  return (
    <div>
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        handleSetActive={handleSetActive}
      />
      <Navbar
        toggle={toggle}
        handleSetActive={handleSetActive}
        ishere={isHere}
      />
      <HeroSection />
      <AboutSection />
      <AdvantagesSection />
      {/* <PackagesSection /> */}
      <QuestionsSection />
      <ContactSection />
      <S.WhatsappIcon
        id="wpp"
        href="https://wa.me/5531984063020"
        target="_blank"
      >
        <img src={whastapp} />
      </S.WhatsappIcon>
      <Footer id="footer" />
    </div>
  );
};
