import styled from "styled-components";
import { Link as LinkS } from 'react-scroll'

export const CardContainer = styled.div`
    height: 696px;
    width: 354px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 16px 32px -8px rgba(80, 52, 94, 0.16);
    padding-bottom: 20px;
`

export const Title = styled.p`
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #363C44;
    align-self: flex-start;
    width: 274px;
    margin-left: 40px;
`
export const Text = styled.li`
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #787F89;
    width: 274px;
`

export const SmallLine = styled.hr`
    width: 96px;
    height: 1px;
    border: none;
    background: linear-gradient(to left, #0066FF 0%, #C055F4 100%);
    margin-left: 40px;
`

export const BigLine = styled.hr`
    width: 274px;
    height: 1px;
    border: none;
    background: linear-gradient(to left, #0066FF 0%, #C055F4 100%);
    margin: 0px;
`

export const FuncionalitiesContainer = styled.ul`
    height: 230px;
`

export const ProgramsContainer = styled.ul`
    height: 80px;
    margin-top: 35px;
`

export const R$ = styled.h3`
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #9AA0AB;
    align-self: flex-end;
    margin: 24px 0px
`

export const Price = styled.h2`
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #49505A;
    margin: 24px 0px
`

export const PriceContainer = styled.div`
    width: 177px;
    display: flex;
    justify-content: space-between;
    margin-left: 100px;
`

export const NavBtn = styled.nav`
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const NavBtnLink = styled(LinkS)`
    padding: 12px 80px 12px 80px;
    text-align: center;
    width: 215px;
    margin: 10px 0px;
    border-radius: 8px;
    background-color: ${props => props.lastcard ? '#0066FF' : '#FFFFFF'};
    white-space: nowrap;
    padding: 12px 32px;
    color: ${props => props.lastcard ? '#F1F4FA' : '#0066FF'};
    font-size: 16px;
    font-family: Roboto;
    outline: ${props => props.lastcard ? 'none' : '1px solid #0066FF'};
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${props => props.lastcard ? 'rgba(46, 129, 255, 1)' : 'rgba(46, 129, 255, 0.25)'};
        /* background: #2E81FF; */
        color: ${props => props.lastcard ? '#F1F4FA' : '#0066FF'};
    }

    &:active {
        transition: all 0.2s ease-in-out;
        background: ${props => props.lastcard ? 'rgba(0, 84, 210, 1)' : 'rgba(0, 84, 210, 0.25)'};
        color: ${props => props.lastcard ? '#F1F4FA' : '#0066FF'};
    }
`