import React from "react";
import * as S from './styles'

export const Sidebar = ({ isOpen, toggle, handleSetActive }) => {
    return (
        <S.SidebarContainer isOpen={isOpen} onClick={toggle}>
            <S.Icon onClick={toggle}>
                <S.CloseIcon />
            </S.Icon>
            <S.SidebarWrapper>
                <S.SidebarMenu>
                    <S.SidebarLink to="about" onClick={toggle} smooth={true} onSetActive={handleSetActive}>O LogHub</S.SidebarLink>
                    <S.SidebarLink to="advantages" onClick={toggle} smooth={true} onSetActive={handleSetActive}>Vantagens</S.SidebarLink>
                    {/* <S.SidebarLink to="packages" onClick={toggle} smooth={true} onSetActive={handleSetActive}>Planos</S.SidebarLink> */}
                    <S.SidebarLink to="contact" onClick={toggle} smooth={true} onSetActive={handleSetActive}>Contato</S.SidebarLink>
                </S.SidebarMenu>
                <S.SideBtnWrap>
                    <S.SidebarRoute href='https://web.whatsapp.com/send?phone=5531984063020' target="_blank">Contratar</S.SidebarRoute>
                </S.SideBtnWrap>
            </S.SidebarWrapper>
        </S.SidebarContainer>
    )
}