import styled from "styled-components";
import {Link as LinkS} from 'react-scroll'
import { mediaScreenWidth } from "../../assets/constants/constants";
import { mediumMediaScreenWidth } from "../../assets/constants/constants";

export const AdvantagesContainer = styled.div`
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-top: 90px;
    margin-bottom: 100px;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        margin-top: 45px;
        margin-bottom: 60px;
    }
`

export const Subtitle = styled.h6`
    font-family: 'Exo 2';
    color: #787F89;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0px;

    
`

export const DividerLine = styled.hr`
    width: 96px;
    height: 1px;
    border: none;
    background: linear-gradient(to left, #0066FF 0%, #C055F4 100%);
    margin: 0px;
`

export const Title = styled.h2`
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #363C44;
    margin: 4px 0px;


    @media screen and (max-width: 780px) {
        transition: 0.2s all ease;
        font-weight: 600;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        margin: 8px 0px 0px 0px;
        width: 500px;
    }

    
    @media screen and (max-width: 500px) {
        transition: 0.4s all ease;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        margin: 8px 0px 0px 0px;
        width: 312px;
    }
`

export const ComputerImage = styled.img`
    width: 33vw;
    min-width: 312px;

    @media screen and (max-width: 780px) {
        transition: 0.2s all ease;
        width: 400px;
        margin-bottom: 40px;
        align-self: center;
    }

    @media screen and (max-width: 450px) {
        transition: 0.2s all ease;
        width: 312px;
        height: 216px;
        margin-bottom: 40px;
        align-self: center;
    }
`

export const AdvantagesContent = styled.div`
    margin-top: 72px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 70px;

    @media screen and (max-width: 780px) {
        transition: 0.6s all ease;
        display: flex;
        flex-direction: column;
        margin-top: 43px;
        width: 390px;
        align-self: center;
    }

    @media screen and (max-width: 470px) {
        transition: 0.6s all ease;
        display: flex;
        flex-direction: column;
        margin-top: 43px;
        width: 312px;
    }  


`

export const AuxiliarTextOne = styled.p`
    font-family: Roboto;
    color: #A53AD9;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0px;


    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        text-align: center;
        width: 400px;
        margin-left: 20px;
    }

    @media screen and (max-width: 450px) {
        transition: 0.2s all ease;
        text-align: center;
        width: 270px;
        margin-left: 20px;
    }
`

export const AuxiliarTextTwo = styled.p`
    font-family: Roboto;
    color: #C055F4;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0px;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        text-align: center;
        width: 400px;
    }

    @media screen and (max-width: 450px) {
        transition: 0.2s all ease;
        text-align: center;
        width: 312px;
    }
`

export const NavBtn = styled.nav`
    margin-top: 25px;
    display: flex;
    align-items: center;
`

export const NavBtnLink = styled.a` /* passar para LinkS se quiser ir para o Formulario*/
    padding: 12px 32px 12px 32px;
    margin: 10px 0px;
    border-radius: 8px;
    background: #0066FF;
    white-space: nowrap;
    padding: 12px 32px;
    color: #F1F4FA;
    font-size: 16px;
    font-family: Roboto;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #2E81FF;
        color: #F1F4FA;
    }

    &:active {
        transition: all 0.2s ease-in-out;
        background: #0054D2;
        color: #F1F4FA;
    }

    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0.2s all ease;
        padding: 12px 122px;
    }
`