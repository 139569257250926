import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'
import { mediaScreenWidth } from "../../assets/constants/constants";
import { mediumMediaScreenWidth } from '../../assets/constants/constants';



export const FooterContainer = styled.div`
    width: 100%;
    height: 226px;
    background-color: #11161E;
    position: relative;


    @media screen and (max-width: 875px) {
        transition: 0.4s all ease;
        min-height: 280px;
    }

    @media screen and (max-width: 560px) {
        transition: 0.4s all ease;
        min-height: 449px;
    }
`

export const FooterLine = styled.hr`
    width: 100%;
    height: 8px;
    border: none;
    background: linear-gradient(to left, #0066FF 0%, #C055F4 100%);
    margin: 0px;
`

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 110px;
    padding-top: 25px;

    @media screen and (max-width: 560px) {
        transition: 0.4s all ease;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        height: 280px;
    }
`

export const Logo = styled(LinkS)`

    
`

export const LogoImage = styled.img`
    width: 133px;
    height: 40px;
    margin-left: 5vw;
    cursor: pointer;

    @media screen and (max-width: 560px) {
        transition: 0.4s all ease;
        margin-left: 7vw;
    }
`

export const InstagramIcon = styled.img`
    margin-right: 5vw;
    cursor: pointer;

    @media screen and (max-width: 560px) {
        transition: 0.4s all ease;
        margin-right: 0px;
        margin-left: 7vw;
    }
`

export const LinksButtons = styled.a`
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #F1F4FA;
    margin-left: 24px;
    text-decoration: none;

    &:hover {
        color: #2E81FF;
    }

    @media screen and (max-width: 560px) {
        transition: 0.4s all ease;
        margin-bottom: 17px;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;

    @media screen and (max-width: 560px) {
        transition: 0.4s all ease;
        flex-direction: column;
        align-items: flex-start;
    }
`

export const DividerLine = styled.hr`
    background: #5F636C;
    border: none;
    width: 90%;
    height: 1px;
    margin-bottom: 25px;
`

export const Stamp = styled.p`
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #9AA0AB;
    text-align: center;

    @media screen and (max-width: 560px) {
        transition: 0.4s all ease;
        text-align: left;
        margin-left: 5vw;
    }
`

export const DocumentsContainer = styled.div`
    position: absolute;
    right: 95px;
    top: 170px;
    display: flex;

    a {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #CBD1DB;
        text-decoration: none;
        margin-left: 20px;
    }
    

    @media screen and (max-width: 875px) {
        transition: 0.4s all ease;
        top: 240px;
        right: 0px;
        width: 312px;
        justify-content: flex-start;

        a {
            margin-left: 0px;
            margin-right: 20px;
        }
    }

    @media screen and (max-width: 560px) {
        transition: 0.4s all ease;
        top: 400px;
        right: 25px;
        width: 312px;
        justify-content: flex-start;

        a {
            margin-left: 0px;
            margin-right: 20px;
        }
    }
`

