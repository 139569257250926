import React from "react"
import * as S from './styles'

export const Problem = (props) => {
    return (
        <S.ProblemContainer>
            <S.Title>{props.title}</S.Title>
            <S.ContentContainer>
                <S.Icon src={props.icon}/>
                <S.Text>{props.text}</S.Text>
            </S.ContentContainer>
        </S.ProblemContainer>
    )
}