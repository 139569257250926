import { Home } from "./pages/Home/home";
import {BrowserRouter as Router} from 'react-router-dom'


function App() {
  return (
    <Router>
      <Home />
    </Router>
  );
}

export default App;
