import styled from "styled-components";
import { mediaScreenWidth } from "../../assets/constants/constants";
import { mediumMediaScreenWidth } from "../../assets/constants/constants";



export const SkillContainer = styled.div`
    width: 260px;


    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.4s all ease;
        width: 400px;
        margin-bottom: 35px;
    }

    @media screen and (max-width: 420px) {
        transition: 0.4s all ease;
        width: 312px;
        margin-bottom: 35px;
    }
`


export const Icon = styled.img`
    width: 64px;
`

export const Title = styled.h5`
    font-family: 'Exo 2';
    color: #49505A;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 16px 0px;
`

export const Text = styled.p`
    font-family: Roboto;
    color: #787F89;
    font-size: 16px;
    line-height: 24px;
    margin: 0px 0px;
`

