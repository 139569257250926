import React from "react"
import * as S from './styles'

export const Skill = ({icon, title, text}) => {
    return (
        <S.SkillContainer>
            <S.Icon src={icon} />
            <S.Title>{title}</S.Title>
            <S.Text>{text}</S.Text>
        </S.SkillContainer>
    )
}