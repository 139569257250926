import styled from "styled-components";
import { FaTimes } from 'react-icons/fa'
import { Link as LinkS } from 'react-scroll'
import { mediaScreenWidth } from "../../assets/constants/constants";


export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 70%;
    height: 40%;
    background: #11161E;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    margin-bottom: 30px;
`

export const CloseIcon = styled(FaTimes)`
    color: #fff;
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
    color: #fff;
    margin-top: 0px;
`

export const SidebarMenu = styled.ul`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    height: 200px;
    margin-left: 0px;
    padding: 0px;

`

export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-weight: 500;
    justify-content: center;
    font-size: 18px;
    text-decoration: none;
    list-style: none;
    transition: 0ms.2s ease-in-out;
    color: #fff;
    cursor: pointer;

    &:active {
        color: #2E81FF;
        transition: 0ms.2s ease-in-out;
    }
`

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`

export const SidebarRoute = styled.a` /* mudar para LinkS quando quiser ir para o form*/
    border-radius: 8px;
    background: #0066FF;
    white-space: nowrap;
    padding: 12px 32px;
    color: #F1F4FA;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:active {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`
