export const questions = [
    {
        question: 'Para que serve o rastreador veicular?',
        answer: `Os rastreadores veiculares têm inúmeras utilidades. No caso de empresas 
        transportadoras ou de serviços, eles servem para informar sobre tudo o que acontece 
        na operação: a localização do veículo, se o motorista está pisando muito forte no acelerador, 
        se a rota combinada está sendo seguida, se a viagem vai atrasar ou não, se as paradas estão 
        demorando muito etc.`},


    {
        question: 'O que o monitoramento veicular me proporciona?',
        answer: `Com nosso sistema, é possível criar locais de interesse para 
        que você receba alerta toda vez que os motoristas entrarem ou saírem desses pontos.`
    },

    {
        question: 'Quais as vantagens da Gestão de Manutenção Loghub?',
        answer: `Cadastre as revisões futuras e não perca nenhuma data importante de vistoria dos 
        veículos da sua frota. Para isso, você só precisa ter em mãos o valor atualizado do odômetro do 
        veículo. Mantenha o funcionamento dos veículos em dia, evite gastos desnecessários e garanta que 
        sua operação de campo não fique parada.`
    },

    {
        question: 'Como funciona o roteirizador de frotas?',
        answer: `Nossa ferramenta de roteirização entrega em poucos segundos uma rota otimizada para cada carro. 
        Com o roteirizador de frotas da LogHub você não precisa mais ficar horas planejando os trajetos de 
        entregas e serviços dos motoristas.`
    },

    {
        question: 'O que a telemetria avançada me proporciona?',
        answer: `Com a telemetria de frota você consegue saber informações importantes sobre como os veículos 
        estão sendo conduzidos, como acelerações e frenagens bruscas, curvas perigosas e velocidades excedidas. 
        Com essas informações à mão, você terá maior certeza de quais decisões tomar no dia a dia e terá mais 
        facilidade para fazer o gerenciamento de transportes da sua empresa.`
    }
]

export const mediaScreenWidth = '360px'
export const mediumMediaScreenWidth = '630px'
export const largeMediaScreenWidth = '900px'
