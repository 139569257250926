import styled from "styled-components";

export const PackagesContainer = styled.div`
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-top: 90px;
    margin-bottom: 100px;
`

export const Subtitle = styled.h6`
    font-family: 'Exo 2';
    color: #787F89;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0px;
`

export const DividerLine = styled.hr`
    width: 96px;
    height: 1px;
    border: none;
    background: linear-gradient(to left, #0066FF 0%, #C055F4 100%);
    margin: 0px;
`

export const Title = styled.h2`
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #363C44;
    margin: 4px 0px;
`

export const PackagesWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 24px;
    margin-top: 72px;

`