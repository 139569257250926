import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'
import { mediaScreenWidth } from '../../assets/constants/constants'
import { mediumMediaScreenWidth } from '../../assets/constants/constants'


export const Nav = styled.nav`
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #11161E;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0ms.8s all ease;
    }
`

export const NavBarContainer = styled.div`
    font-family: Roboto;
    font-style: Medium;
    font-size: 16px;
    color: #F1F4FA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`

export const NavLogo = styled(LinkS)`
    cursor: pointer;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        color: #fff;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
}
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        display: none;
    }
`

export const NavItem = styled.li`
    height: 60px;
`

export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    border-bottom: ${props => props.ishere === props.to ? '3px solid #fff' : 'none'};
    transition: border-bottom 0.1s ease-in-out;
`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        display: none;
    }
`

export const NavBtnLink = styled.a` /*mudar para LinkS caso o objetivo seja direcionar o user para o formulario*/
    padding: 12px 32px 12px 32px;
    border-radius: 8px;
    background: #0066FF;
    white-space: nowrap;
    padding: 12px 32px;
    color: #F1F4FA;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #2E81FF;
        color: #F1F4FA;
    }

    &:active {
        transition: all 0.2s ease-in-out;
        background: #0054D2;
        color: #F1F4FA;
    }

`