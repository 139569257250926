import { Typography, Accordion } from "@mui/material";
import styled from "styled-components";
import { mediaScreenWidth } from "../../assets/constants/constants";
import { mediumMediaScreenWidth } from "../../assets/constants/constants";


export const QuestionsContainer = styled.div`
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Subtitle = styled.h6`
    font-family: Roboto;
    color: #787F89;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 70px;
    margin-top: 15px;
    width: 698px;
    text-align: center;


    @media screen and (max-width: 850px) {
        transition: 0.2s all ease;
        width: 80%;
        text-align: center;
        height: 120px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0.2s all ease;
        width: 312px;
        text-align: center;
        height: 120px;
        margin-bottom: 20px;
    }
`

export const Title = styled.h2`
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #363C44;
    margin: 20px 0px;


    @media screen and (max-width: ${mediumMediaScreenWidth}) {
        transition: 0.2s all ease;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
    }

    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0.2s all ease;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }
`

export const AccordionContainer = styled.div`
    width: 50vw;
    min-width: 310px;
    margin-bottom: 50px;

    @media screen and (max-width: ${mediaScreenWidth}) {
        transition: 0.2s all ease;
        width: 310px;
    }
`

export const Question = styled(Typography)`
    font-family: 'Exo 2'!important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 36px !important;
    color: #49505A !important;
`

export const Answer = styled(Typography)`
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #787F89 !important;
`

