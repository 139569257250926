import React from "react"
import * as S from './styles'
import logo from '../../assets/imgs/logHub-logo.svg'
import { FaBars } from 'react-icons/fa'


export const Navbar = ({ toggle, handleSetActive, ishere }) => {
    return (
        <>
            <S.Nav>
                <S.NavBarContainer>
                    <S.NavLogo to="home" smooth={true} offset={-80} spy={true} activeClass="active" onSetActive={handleSetActive} ishere={ishere}><img src={logo} /></S.NavLogo>
                    <S.MobileIcon onClick={toggle}>
                        <FaBars />
                    </S.MobileIcon  >
                    <S.NavMenu>
                        <S.NavItem>
                            <S.NavLinks to="about" smooth={true} offset={-80} spy={true} activeClass="active" onSetActive={handleSetActive} ishere={ishere}>O LogHub</S.NavLinks>
                        </S.NavItem>
                        <S.NavItem>
                            <S.NavLinks to="advantages" smooth={true} offset={-80} spy={true} activeClass="active" onSetActive={handleSetActive} ishere={ishere}>Vantagens</S.NavLinks>
                        </S.NavItem>
                        {/* <S.NavItem>
                            <S.NavLinks to="packages" smooth={true} offset={-80} spy={true} activeClass="active" onSetActive={handleSetActive}>Planos</S.NavLinks>
                        </S.NavItem> */}
                        <S.NavItem>
                            <S.NavLinks to="contact" smooth={true} offset={-80} spy={true} activeClass="active" onSetActive={handleSetActive} ishere={ishere}>Contato</S.NavLinks>
                        </S.NavItem>
                    </S.NavMenu>
                    <S.NavBtn>
                        {/* <S.NavBtnLink to="contact" smooth={true} offset={-80} >Contratar</S.NavBtnLink> */}
                        <S.NavBtnLink href='https://web.whatsapp.com/send?phone=5531984063020' target="_blank">Contratar</S.NavBtnLink>
                    </S.NavBtn>
                </S.NavBarContainer>
            </S.Nav>
        </>
    )

}